<template>
    <!--begin::Dashboard-->
    <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
        <b-form @submit="Update" @reset="Reset">
            <div id="form" class="d-flex justify-content-around flex-wrap">
                <div class="mt-1 mb-5 d-flex flex-column justify-content-between" style="width: 20%">
                    <router-link :to="{ name: 'classes/edit', params: { id: this.$route.params.id } }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            {{ $t('CONTROLLERS.CANCEL') }}
                        </span>
                    </router-link>
                    <img :src="classData.miniature" style="max-width: 100%" class="mx-auto rounded" />
                    <label class="mt-2 btn btn-primary btn-md btn-block" for="miniature">{{ $t('MEDIA.SELECT') }}</label>
                    <input
                        type="file"
                        ref="miniature"
                        id="miniature"
                        name="miniature"
                        accept="image/*"
                        @change="onMiniatureChange"
                        style="display: none"
                    />
                </div>
                <b-form-group style="width: 70%" class="d-flex flex-column justify-content-between">
                    <b-form-group id="input-group-1" :label="$t('CLASS.NAME.LABEL')" label-for="input-1">
                        <b-form-input
                            id="input-1"
                            v-model.lazy="classData.title"
                            type="text"
                            required
                            :placeholder="$t('CLASS.NAME.INPUT')"
                        >
                        </b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" :label="$t('CLASS.DESCRIPTION.LABEL')" label-for="input-2">
                        <b-form-textarea
                            id="input-2"
                            v-model="classData.description"
                            type="text"
                            rows="3"
                            max-rows="6"
                            required
                            :placeholder="$t('CLASS.DESCRIPTION.INPUT')"
                        >
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group id="input-group-3" :label="$t('CLASS.CERTIFICATE.LABEL')" label-for="input-3">
                        <b-form-textarea
                            id="input-3"
                            v-model="classData.text_certificate"
                            type="text"
                            rows="3"
                            max-rows="6"
                            :placeholder="$t('CLASS.CERTIFICATE.INPUT')"
                        >
                        </b-form-textarea>
                    </b-form-group>
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="w-50 d-flex align-items-center justify-content-center">
                            <span class="svg-icon svg-icon-md mr-4">
                                <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                            </span>
                            <p v-if="!classData.summary" class="m-0">Pas de fiche synthèse ajoutée</p>
                            <p v-else class="m-0">{{ file1Name }}</p>
                        </div>
                        <div class="w-50 d-flex align-items-center justify-content-center">
                            <b-form-group id="input-group-4" label-for="input-4" class="w-100 m-0">
                                <label v-if="!classData.summary" class="mt-4 btn btn-primary btn-md btn-block" for="file1"
                                    >Ajouter une fiche synthèse (.pdf)</label
                                >
                                <label v-else class="mt-4 btn btn-primary btn-md btn-block" for="file1"
                                    >Modifier la fiche synthèse (.pdf)</label
                                >
                                <input
                                    type="file"
                                    ref="file1"
                                    id="file1"
                                    name="file1"
                                    accept="application/pdf"
                                    style="display: none"
                                    @change="onSummaryChange"
                                />
                            </b-form-group>
                        </div>
                    </div>
                    <b-alert v-model="showPdfSizeAlert" variant="danger"> Votre fichier dépasse la taille maximale (2Mo). </b-alert>
                    <b-alert v-model="showPdfTypeAlert" variant="danger"> Votre fichier n'est pas un PDF valide. </b-alert>
                    <b-form-group id="input-group-4" :label="$t('CLASS.TEASER.LABEL')" label-for="input-4">
                        <b-form-input
                            id="input-4"
                            v-model="classData.teaser"
                            type="text"
                            :placeholder="$t('CLASS.TEASER.INPUT')"
                            @keyup="isValidUrl(classData.teaser)"
                        >
                        </b-form-input>
                    </b-form-group>
                    <b-alert v-model="alertURL" variant="danger"> Votre lien ne correspond pas à une vidéo youtube. </b-alert>
                    <b-form-group class="w-100" :label="$t('CLASS.THEME.LABEL')" label-for="selectTheme">
                        <b-form-select v-model="selectedTheme" :options="themesOptions" id="selectTheme"></b-form-select>
                    </b-form-group>
                </b-form-group>
                <b-form-group
                    class="border-top border-bottom border-secondary py-7 m-0 w-100 d-flex align-items-center justify-content-center flex-column"
                    style="margin: 0 5rem !important"
                >
                    <b-row class="form-group d-flex justify-content-around align-items-center w-100">
                        <div style="width: 50%">
                            <b-form-checkbox :disabled="scorm" name="linear" v-model="classData.linear" id="linear" switch>
                                {{ $t('CLASS.LINEAR.FALSE') }}
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                {{ $t('CLASS.LINEAR.INFOFALSE') }}
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex align-items-center flex-wrap">
                            <label class="m-0">{{ $t('CLASS.DURATION.LABEL') + '*' }}</label>
                            <b-form-input
                                type="number"
                                v-model="classData.duration"
                                :value="classData.duration"
                                placeholder="60"
                                required
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                size="sm"
                                class="ml-3"
                                style="width: 6rem"
                            >
                            </b-form-input>
                            <label class="m-0 ml-3">{{ $t('CLASS.DURATION.UNIT') }}</label>
                            <p class="text-muted text-left w-100">
                                {{ $t('CLASS.DURATION.INFO') }}
                            </p>
                        </div>
                    </b-row>
                    <b-row class="form-group" style="width: 100%">
                        <div style="width: 50%">
                            <b-form-checkbox name="catalog" v-model="classData.catalog" id="catalog" switch>
                                <span v-if="classData.catalog === true">{{ $t('CLASS.CATALOG.TRUE.LABEL') }}</span>
                                <span v-else>{{ $t('CLASS.CATALOG.FALSE.LABEL') }}</span>
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                <span v-if="classData.catalog === true">{{ $t('CLASS.CATALOG.TRUE.INFO') }}</span>
                                <span v-else>{{ $t('CLASS.CATALOG.FALSE.INFO') }}</span>
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex align-items-center flex-wrap"></div>
                    </b-row>
                    <b-row class="form-group" style="width: 100%">
                        <div style="width: 50%">
                            <b-form-checkbox name="scorm" disabled="true" :checked="scorm" v-model="scorm" id="scorm" switch>
                                <span>{{ $t('CLASS.SCORM.LABEL') }}</span>
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                <span>{{ $t('CLASS.SCORM.INFO') }}</span>
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex flex-column flex-wrap" v-if="scorm">
                            <div class="d-flex flex-row">
                                <b-form-file
                                    id="scorm_file"
                                    type="file"
                                    ref="scorm_file"
                                    name="scorm_file"
                                    :v-model="scorm_file"
                                    browse-text="Charger le Scorm"
                                    :placeholder="classData.scormName"
                                    accept=".zip"
                                    :style="{ outline: error.scorm ? '1px solid red' : '', borderRadius: '5px' }"
                                    @input="loadScorms"
                                />
                            </div>
                        </div>
                    </b-row>
                    <b-row class="form-group" style="width: 100%">
                        <div style="width: 50%">
                            <b-form-checkbox :disabled="scorm" name="classroom" v-model="classData.classroom" id="classroom" switch>
                                <span>{{ $t('CLASS.CLASSROOM.LABEL') }}</span>
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                <span>{{ $t('CLASS.CLASSROOM.INFO') }}</span>
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex flex-column flex-wrap" v-if="classData.classroom === true">
                            <div class="d-flex flex-row align-items-center">
                                <label class="m-0">{{ $t('CLASS.MAX_INTERNS.LABEL') + '*' }}</label>
                                <b-form-input
                                    type="number"
                                    v-model="classData.max_intern"
                                    :value="classData.max_intern"
                                    required
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    size="sm"
                                    class="ml-3"
                                    style="width: 6rem"
                                >
                                </b-form-input>
                            </div>
                            <p class="text-muted text-left w-100">{{ $t('CLASS.MAX_INTERNS.INFO') }}{{ classData.classroom }}</p>
                        </div>
                    </b-row>
                </b-form-group>
                <div class="mt-5 text-center">
                    <b-button
                        type="submit"
                        class="mx-5 btn-lg"
                        variant="primary"
                        :disabled="validURL === false || showPdfSizeAlert || showPdfTypeAlert"
                    >
                        {{ $t('CONTROLLERS.UPDATE') }}
                    </b-button>
                    <button type="reset" class="btn btn-danger mx-5 btn-lg">
                        {{ $t('CONTROLLERS.ERASE') }}
                    </button>
                </div>
            </div>
        </b-form>

        <div
            v-if="submit"
            style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background-color: #ffffff9e;
                backdrop-filter: blur(5px);
            "
            class="d-flex flex-column justify-content-around align-items-center"
        >
            <div style="z-index: 200" class="d-flex flex-column justify-content-around align-items-center">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
                <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { LIST_ALL_THEMES } from '@/core/services/store/api/theme.service';
import { LIST_CLASSE } from '@/core/services/store/api/classe.service';
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
    name: 'admin-form-edit-classes',
    data() {
        return {
            file1: '',
            file1Name: '',
            scorm: false,
            scorm_file: '',
            error: {
                scorm: false
            },
            miniature: '',
            showPdfSizeAlert: false,
            showPdfTypeAlert: false,
            submit: false,
            validURL: null,
            alertURL: false,
            selectedTheme: null,
            themesOptions: []
        };
    },
    async mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('MENU.DASHBOARD') }]);
        if (this.$route.params.id) {
            this.$store.dispatch(LIST_CLASSE, this.$route.params.id).then(() => {
                if (this.classData.theme) {
                    this.classData.theme.id ? (this.selectedTheme = this.classData.theme.id) : (this.selectedTheme = null);
                }
                this.classData.linear ? (this.classData.linear = true) : (this.classData.linear = false);
                this.classData.catalog ? (this.classData.catalog = true) : (this.classData.catalog = false);
                this.classData.classroom ? (this.classData.classroom = true) : (this.classData.classroom = false);
            });
        } else {
            this.$router.push({ name: 'admin/dashboard' });
        }
        await this.$store.dispatch(LIST_ALL_THEMES).then((result) => {
            this.themesOptions = [
                {
                    value: null,
                    text: 'Sélectionnez un thème de formation'
                }
            ];
            result &&
                result.data.map((theme) => {
                    this.themesOptions.push({
                        value: theme.id,
                        text: theme.name
                    });
                });
        });

        //Couper une partie de l'URL pour ne garder que le nom du fichier uploadé
        if (this.classData.summary) {
            let index = this.classData.summary.lastIndexOf('/');
            this.file1Name = this.classData.summary.slice(index + 1);
        }
    },
    computed: {
        ...mapGetters({
            classData: 'getClasse',
            themes: 'getAllThemes'
        })
    },
    watch: {
        classData() {
            if (this.classData.scorm_id !== null) {
                this.scorm = true;
            } else {
                this.scorm = false;
            }
        }
    },
    methods: {
        Reset(evt) {
            evt.preventDefault();
            this.ReloadClasse();
        },
        checkData() {
            if (this.classData.classroom === true && this.classData.max_intern == 0) {
                this.$bvToast.toast('Le nombre maximum de stagiaire ne peut pas être égal à 0', {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
                return false;
            } else if (this.classData.duration == 0) {
                this.$bvToast.toast('La durée de la formation ne peut pas être égale à 0', {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
                return false;
            } else {
                return true;
            }
        },
        Update(evt) {
            evt.preventDefault();
            if (this.checkData()) {
                this.submit = true;
                let data = new FormData();
                data.append('miniature', this.miniature);
                data.append('file1', this.file1);
                data.append('id', this.classData.id);
                data.append('title', this.classData.title);
                data.append('user_id', this.$store.getters.currentUser.id);
                data.append('description', this.classData.description);
                data.append('scorm', this.scorm);
                data.append('scorm_file', this.scorm_file);
                // data.append('goal', this.classData.goal);
                data.append('linear', this.classData.linear === true ? 1 : 0);
                data.append('catalog', this.classData.catalog === true ? 1 : 0);
                data.append('text_certificate', this.classData.text_certificate);
                data.append('duration', this.classData.duration);
                data.append('teaser', this.classData.teaser);
                data.append('theme', this.selectedTheme);
                data.append('classroom', this.classData.classroom === true ? 1 : 0);
                data.append('max_intern', this.classData.classroom === true ? this.classData.max_intern : null);
                ApiService.post(process.env.VUE_APP_API_URL + '/academy/classes/update', data)
                    .then((result) => {
                        if (result.data) {
                            this.$router.push({
                                name: 'classes/edit',
                                params: { id: this.classData.id }
                            });
                        }
                    })
                    .catch((error) => {
                        this.submit = false;
                        this.$bvToast.toast(error.response.data.message, {
                            title: "Une erreur s'est produite : ",
                            variant: 'danger',
                            solid: true
                        });
                    });
            }
        },
        loadScorms() {
            this.error.scorm_id = false;
            this.scorm_file = this.$refs.scorm_file.files[0];
        },
        ReloadClasse() {
            this.$store.dispatch(LIST_CLASSE, this.$route.params.id).then(() => {
                this.$forceUpdate();
            });
            this.submit = false;
        },
        DeleteClasse() {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/classes/delete/' + this.classData.id)
                .then((result) => {
                    if (result.data) {
                        this.$router.push({ name: 'admin/dashboard' });
                    }
                })
                .catch((error) => {
                    this.errorMessage = error.message;
                });
            this.$router.push({
                name: 'classes/edit',
                params: { id: this.classData.id }
            });
        },
        onMiniatureChange() {
            const regex = /(^image)([/]{1})[a-zA-Z+]{1,}/g;
            if (this.$refs.miniature.files[0].type.match(regex)) {
                this.miniature = this.$refs.miniature.files[0];
                this.classData.miniature = URL.createObjectURL(this.miniature);
            } else {
                confirm('Mauvais format de fichier');
            }
        },
        onSummaryChange() {
            this.file1Name = this.$refs.file1.files[0].name;
            this.file1 = this.$refs.file1.files[0];

            if (this.checkPdfSize(this.file1) === true && this.checkPdfType(this.file1) === true) {
                this.classData.summary = URL.createObjectURL(this.file1);
            }
        },

        checkPdfSize(file) {
            if (file.size < 2000000) {
                this.showPdfSizeAlert = false;
                return true;
            } else {
                this.showPdfSizeAlert = true;
                this.showPdfTypeAlert = false;
                return false;
            }
        },

        checkPdfType(file) {
            if (file.type === 'application/pdf') {
                this.showPdfTypeAlert = false;
                return true;
            } else {
                this.showPdfTypeAlert = true;
                this.showPdfSizeAlert = false;
                return false;
            }
        },
        isValidUrl(url) {
            let regexURL =
                /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.?\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[?6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1?,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00?a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u?00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
            if (regexURL.test(url) === true && (url.includes('youtube.com') || url.includes('youtu.be'))) {
                this.validURL = true;
                this.alertURL = false;
            } else if (url === '') {
                this.validURL = null;
                this.alertURL = false;
            } else {
                this.validURL = false;
                this.alertURL = true;
            }
        }
    }
};
</script>
<style scoped>
.nl2br {
    max-height: 45%;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}
</style>
